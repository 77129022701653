import React from "react";
import { Button } from "semantic-ui-react";

import "./style.css";
import clientConfig from "../../client.json";
import IconPlay from "../../assets/images/ic-play.png";

const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  description?: string = "description";
  title?: string = "title";
}

interface LaunchProps {
  Launch: () => void;
}

export const LaunchView: React.FC<LaunchProps> = (props: LaunchProps) => {
  return (
    <div id="launchContainer">
      <div>
        <h1>{client.description}</h1>
        <Button
          size="massive"
          // color="blue"
          // circular
          // icon="play"
          onClick={() => props.Launch()}
          style={{ backgroundColor: "transparent" }}
        >
          <img
            src={IconPlay}
            alt="icon play"
            style={{ width: 62, height: 62 }}
          />
        </Button>
      </div>
      <img
        alt="Away Digital Home Logo"
        src="/logo.png"
        style={{ width: 100, position: "absolute", bottom: 50, right: 10 }}
      />
    </div>
  );
};
